import classNames from 'clsx';
import PropTypes from 'prop-types';
import { Component } from 'react';
import cssVars from 'src/common/styles/variables';
import { types } from 'src/common/utils/guideItemTypes';
import withMouseHover from '../../decorators/hovers/withMouseHover';
import { isMedium } from '../../utils/breakpoints';
import { isDiscordMode } from '../../utils/discord';
import { getCssStyle } from '../../utils/getCssStyle';
import canEmbed from '../../utils/guideItem/canEmbed';
import FavoriteControl from '../shared/controls/favorite/FavoriteControl';
import FavoriteStyleContext from '../shared/controls/favorite/context/FavoriteStyleContext';
import ShareDialog from '../shared/dialog/ShareDialog';
import ShareIcon from '../shared/svgIcons/player/ShareIcon';
import DonateButton from './DonateButton';
import css from './social-controls.module.scss';

class SocialControls extends Component {
  static propTypes = {
    canFollow: PropTypes.bool.isRequired,
    hidePlayButton: PropTypes.bool.isRequired,
    guideItem: PropTypes.object.isRequired,
    handleSharing: PropTypes.func.isRequired,
    breakpoint: PropTypes.number.isRequired,

    // withMouseHover
    handleMouseEnter: PropTypes.func.isRequired,
    handleMouseLeave: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isShowingShareDialog: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.guideItem.guideId !== this.props.guideItem.guideId) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isShowingShareDialog: false,
      });
    }
  }

  toggleShareDialog = () => {
    if (!this.state.isShowingShareDialog) {
      this.props.handleSharing();
    }

    this.setState({
      isShowingShareDialog: !this.state.isShowingShareDialog,
    });
  };

  render() {
    const {
      guideItem,
      canFollow,
      hidePlayButton,
      breakpoint,

      // withMouseHover
      handleMouseEnter,
      handleMouseLeave,
    } = this.props;
    const isMediumBreakpoint = isMedium(breakpoint);
    const shouldShowShareButton =
      isMediumBreakpoint &&
      guideItem?.actions?.share?.canShare &&
      !isDiscordMode();
    const shareMeta = {
      guideId: guideItem.guideId,
      image: guideItem.image,
      title: guideItem.title,
      subtitle: guideItem.subtitle,
      shareUrl: guideItem?.actions?.share?.shareUrl,
      shareText: guideItem?.actions?.share?.shareText,
      slogan: guideItem?.metadata?.properties?.station?.slogan,
    };

    let donateAction = guideItem?.actions?.donate;

    // find donate action in donatePrompt
    if (!donateAction && guideItem.containerItems) {
      guideItem.containerItems.some(({ children = [] }) => {
        const promptItem = children.find(({ type }) => type === types.prompt);
        const action = promptItem?.actions?.donate;
        if (action) donateAction = action;
        return !!action;
      });
    }

    const canDonate = donateAction?.canDonate;
    const donateButtonText = donateAction?.text;
    const donateButtonUrl = donateAction?.url;
    const shouldShowDonateButton =
      isMediumBreakpoint && !!canDonate && !!donateButtonUrl;

    return (
      <>
        <div className={css.wrapper}>
          {canFollow && (
            <div
              id="profileFavoriteContainer"
              className={classNames(css.profileFavoriteContainer, {
                [css.withoutPlayButton]: hidePlayButton,
              })}
            >
              <div id="profileFavorite" className={css.actionIconContainer}>
                <FavoriteStyleContext.Provider
                  value={{ handleMouseEnter, handleMouseLeave }}
                >
                  <FavoriteControl
                    profileGuideId={guideItem.guideId}
                    fill={getCssStyle('--text-color')}
                    hoverFill={
                      isDiscordMode()
                        ? getCssStyle('--text-color')
                        : getCssStyle('--knighthood-grey')
                    }
                    showIconLabel={!isMediumBreakpoint || isDiscordMode()}
                  />
                </FavoriteStyleContext.Provider>
              </div>
            </div>
          )}
          {shouldShowShareButton && (
            <div
              id="profileShareContainer"
              className={classNames(
                css.actionIconContainer,
                css.profileShareContainer,
              )}
            >
              <div
                id="profileShare"
                data-testid="profileShareButton"
                onClick={this.toggleShareDialog}
              >
                <ShareIcon
                  width={cssVars['--large-icon-size']}
                  height={cssVars['--large-icon-size']}
                />
              </div>
            </div>
          )}
        </div>
        {shouldShowShareButton && (
          <ShareDialog
            key="profileShareDialog"
            shareMeta={shareMeta}
            showEmbed={canEmbed(guideItem)}
            isActive={this.state.isShowingShareDialog}
            toggleShareDialog={this.toggleShareDialog}
          />
        )}
        {shouldShowDonateButton && (
          <DonateButton
            key="donate"
            text={donateButtonText}
            url={donateButtonUrl}
          />
        )}
      </>
    );
  }
}

export default withMouseHover(SocialControls);
