import assetUrl from 'src/common/utils/assetUrl';

export default async function handleAutoplayCheck(autoplay) {
  const audio = document.createElement('audio');

  audio.src = assetUrl('assets/media/blank.mp3');

  try {
    await audio.play();
    autoplay();
  } catch {
    async function playOnUserInteraction() {
      try {
        await audio.play();
        autoplay();
      } finally {
        document.removeEventListener('click', playOnUserInteraction);
        document.removeEventListener('touchstart', playOnUserInteraction);
      }
    }

    document.addEventListener('click', playOnUserInteraction);
    document.addEventListener('touchstart', playOnUserInteraction);
  }
}
