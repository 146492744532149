import { isTopic } from '@tunein/web-common';
import classNames from 'clsx';
import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  BOTTOM_BANNER_ENABLED,
  FREESTAR_ENABLED,
  FREESTAR_PRIMIS_VIDEO_ENABLED,
} from 'src/common/constants/experiments/ads';
import { selectExperiment } from 'src/common/selectors/config';
import cssVariables from 'src/common/styles/variables';
import {
  getDisplayAdAttributes,
  gptSlotNames,
} from 'src/common/utils/ads/config';
import { mintSingleton } from '../../../client/mint';
import { logClientError, logWebActivity } from '../../actions/logging';
import { tune } from '../../actions/player';
import { doNotFetch } from '../../constants/locationState';
import { playerStatuses } from '../../constants/playerStatuses';
import { TOPIC_ID } from '../../constants/queryParams';
import connectWithFailureState from '../../decorators/connectWithFailureState';
import withCollapsibleState from '../../decorators/withCollapsibleState';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import getRadioContext from '../../routes/utils/getRadioContext';
import { selectIsConsentReady } from '../../selectors/app';
import { selectIsAuthenticated } from '../../selectors/auth';
import {
  selectGuideItemPathname,
  selectIsTunePrimed,
  selectPlayerStatus,
  selectTunedGuideId,
} from '../../selectors/player';
import commonCss from '../../styles/common.module.scss';
import { isMedium } from '../../utils/breakpoints';
import { fetchProfile } from '../../utils/connectors/fetchProfile';
import { isDiscordMode } from '../../utils/discord';
import ifThenAppendCBSTritonScript from '../../utils/dom/ifThenAppendCBSTritonScript';
import fullUrl from '../../utils/fullUrl';
import { buildGuideItemPathnameWithLogger } from '../../utils/guideItem/getGuideItemPathname';
import isCBSAffiliated from '../../utils/guideItem/isCBSAffiliated';
import {
  behaviors as GuideItemBehaviors,
  isExpiredGame,
  isGame,
  isPodcastProfile,
  isShow,
  types,
} from '../../utils/guideItemTypes';
import SeoHelmet from '../SeoHelmet';
import DisplayAd from '../ads/DisplayAd';
import FreeStarVideoAdContainer from '../ads/FreeStarVideoAdContainer';
import ContainerItemsLoader from '../containerItems/ContainerItemsLoader';
import UpsellRightSidebar from '../subscription/UpsellRightSidebar';
import canonicalLinkObj from '../utils/canonicalLinkObj';
import { createAndroidDeepLinks } from '../utils/createAndroidDeepLinks';
import { createInternationalSeoLinks } from '../utils/createInternationalSeoLinks';
import { createProfileMetaDescription } from '../utils/pageMetaDescriptions';
import { createProfilePageMetaKeywords } from '../utils/pageMetaKeywords';
import { createProfilePageTitle } from '../utils/pageTitles';
import Header from './Header';
import css from './profile.module.scss';
import createOEmbedLinks from './utils/createOEmbedLinks';
import createOgMeta from './utils/createOgMeta';
import handleAutoplayCheck from './utils/handleAutoplayCheck';
import showProfileAdInContentArea from './utils/showProfileAdInContentArea';

/* eslint-disable react/prefer-stateless-function */
export class Profile extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    guideItem: PropTypes.object.isRequired,
    routeProps: PropTypes.object.isRequired,
    selectedGuideId: PropTypes.string.isRequired,
    actions: PropTypes.object.isRequired,
    shouldShowMore: PropTypes.bool.isRequired,
    handleMoreClick: PropTypes.func.isRequired,
    topicGuideId: PropTypes.string,
    audioClipId: PropTypes.string,
    playerStatus: PropTypes.string,
    isTunePrimed: PropTypes.bool,
    isMobile: PropTypes.bool.isRequired,
    allowAutoplay: PropTypes.bool.isRequired,
    breakpoint: PropTypes.number.isRequired,
    isTopicIdInvalid: PropTypes.bool.isRequired,
    resetCollapsibleState: PropTypes.func.isRequired,
    canShowAds: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    isDiscord: PropTypes.bool.isRequired,
    isFreestarEnabled: PropTypes.bool.isRequired,
    isBottomBannerEnabled: PropTypes.bool.isRequired,
    isFreestarPrimisVideoEnabled: PropTypes.bool.isRequired,
    sponsorshipEnabled: PropTypes.bool.isRequired,
  };

  static contextType = LocationAndLocalizationContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      canBrowserAutoplay: false,
      shouldShowProfileAdInContentArea: undefined,
    };

    const { location, station } = props?.guideItem.metadata?.properties || {};
    mintSingleton.updateState('countryRegionId', location?.countryRegionId);
    mintSingleton.updateState('stationLanguage', station?.language);
    mintSingleton.updateState('selectedGuideId', props.selectedGuideId);
  }

  componentDidMount() {
    const {
      isMobile,
      isDiscord,
      playerStatus,
      isTunePrimed,
      guideItem,
      topicGuideId,
      isTopicIdInvalid,
    } = this.props;

    if (isCBSAffiliated(guideItem)) ifThenAppendCBSTritonScript();

    const behaviorAction = guideItem.behaviors?.primaryButton?.actionName;
    const subscriptionRequired =
      behaviorAction === GuideItemBehaviors.subscribe;
    const isPrimed = isMobile ? isTunePrimed : true;
    const isPlayerIdle = playerStatus === playerStatuses.idle;
    const canAutoPlay =
      !subscriptionRequired && isPrimed && isPlayerIdle && !isDiscord;

    // In the case that a topicid is supplied, but it isn't valid (e.g. fails to load), we
    // shouldn't attempt to autoplay it.
    if ((!topicGuideId && canAutoPlay) || (!isTopicIdInvalid && canAutoPlay)) {
      handleAutoplayCheck(this.handleAutoplayReady.bind(this)).catch(() =>
        this.props.actions.logWebActivity(
          'autoPlayDisabled',
          true,
          guideItem.guideId,
        ),
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      selectedGuideId,
      allowAutoplay,
      history,
      location,
      match,
      actions,
      resetCollapsibleState,
      guideItemPathname,
      isAuthenticated,
      canShowAds,
      breakpoint,
      tunedGuideId,
      isConsentReady,
      isDiscord,
    } = this.props;

    const { search, pathname } = location;

    // Check that now playing guideItem matches current profile guideItem
    if (
      prevProps.tunedGuideId !== tunedGuideId &&
      isTopic(tunedGuideId) &&
      guideItemPathname === pathname &&
      !isDiscord
    ) {
      const searchParams = new URLSearchParams(search);
      searchParams.set(TOPIC_ID, tunedGuideId.slice(1));
      history?.replace({
        pathname,
        search: searchParams.toString(),
        state: {
          [doNotFetch]: true,
        },
      });
    }

    if (prevProps.selectedGuideId !== selectedGuideId) {
      resetCollapsibleState();
    }

    if (prevProps.isAuthenticated !== isAuthenticated) {
      actions.fetchProfile(getRadioContext, { history, location, match });
    }

    if (
      allowAutoplay &&
      this.state.canBrowserAutoplay &&
      isConsentReady &&
      (!prevProps.allowAutoplay ||
        !prevState.canBrowserAutoplay ||
        !prevProps.isConsentReady)
    ) {
      this.autoPlay();
    }

    if (
      !isDiscordMode() &&
      (prevProps.breakpoint !== breakpoint ||
        prevProps.canShowAds !== canShowAds ||
        this.state.shouldShowProfileAdInContentArea === undefined)
    ) {
      this.updateShowProfileAdInContentArea();
    }
  }

  componentWillUnmount() {
    // Notify Mint that we're no longer on the profile page
    mintSingleton.updateState('selectedGuideId', null);
  }

  handleAutoplayReady() {
    this.setState({ canBrowserAutoplay: true });

    if (this.props.isConsentReady) {
      this.autoPlay();
    }
  }

  autoPlay() {
    const { formats, st } = this.context.location.query;
    const { guideItem, actions, topicGuideId, audioClipId, allowAutoplay } =
      this.props;

    if (allowAutoplay) {
      actions.tune({
        audioClipId,
        topicGuideId,
        guideItem,
        formats: formats ? formats.toLowerCase() : formats,
        isAutoplayed: true,
        playFromPosition: st,
      });
    }
  }

  /**
   * Setting this flag in state because the server would otherwise set it to true by default, potentially causing
   * UpsellRightSidebar to report a view once in Profile.js again in RightSide.js, once the client sets the true value
   * of app.breakpoint in the store.
   */
  updateShowProfileAdInContentArea() {
    const { breakpoint, canShowAds } = this.props;
    this.setState({
      shouldShowProfileAdInContentArea:
        showProfileAdInContentArea(breakpoint) && canShowAds,
    });
  }

  render() {
    const {
      guideItem,
      selectedGuideId,
      shouldShowMore,
      handleMoreClick,
      breakpoint,
      routeProps,
      canShowAds,
      actions,
      location,
      topicGuideItem,
      isDiscord,
      isMobile,
      isFreestarEnabled,
      isBottomBannerEnabled,
      isFreestarPrimisVideoEnabled,
      sponsorshipEnabled,
    } = this.props;
    const { shouldShowProfileAdInContentArea } = this.state;
    const { getLocalizedText } = this.context;
    const canExpand = guideItem.actions?.expand?.canExpand;
    const properties = guideItem.metadata?.properties || {};
    const {
      station: { band, callSign, frequency } = {},
      program,
      seoInfo: { keywords: guideItemSEOKeywords } = {},
      guideInfo: { description: guideInfoDescription } = {},
    } = properties || {};
    const {
      actions: {
        share: { logoUrl } = {},
        play: { isLive } = {},
      } = {},
      properties: { teamsInfo } = {},
      title: guideItemTitle,
      type: guideItemType,
      rootGenre,
    } = guideItem;

    const shouldShowDisplayAd =
      canShowAds && !isMedium(breakpoint) && !isBottomBannerEnabled;
    const shouldShowInContentVideoAd =
      canShowAds &&
      !isMedium(breakpoint) &&
      !sponsorshipEnabled &&
      isFreestarEnabled &&
      isFreestarPrimisVideoEnabled;

    const pageTitle = createProfilePageTitle({
      getLocalizedText,
      title: guideItemTitle,
      properties,
      type: guideItemType,
    });

    const description = createProfileMetaDescription({
      getLocalizedText,
      type: topicGuideItem?.type || guideItemType,
      description: topicGuideItem?.description || guideItem.description,
      title: guideItemTitle,
      ...properties,
    });

    const keywords = createProfilePageMetaKeywords({
      title: guideItemTitle,
      rootGenre,
      keywords: guideItemSEOKeywords,
    });
    const guideItemPathName = buildGuideItemPathnameWithLogger(guideItem)(
      actions.logClientError,
    );
    const links = [
      canonicalLinkObj(guideItemPathName),
      ...createInternationalSeoLinks(
        guideItemPathName,
        properties?.station?.languageCode || properties?.program?.languageCode,
      ),
      ...createOEmbedLinks(
        guideItem.guideId,
        guideItemTitle,
        guideItem.actions?.embed,
      ),
      ...createAndroidDeepLinks(guideItem.guideId),
    ];

    const fullUrlWithAbsoluteRoot = fullUrl(guideItemPathName);

    const meta = [
      ...(isExpiredGame(program)
        ? [{ name: 'robots', content: 'noindex' }]
        : []),
      ...createOgMeta(
        guideItem,
        fullUrlWithAbsoluteRoot,
        topicGuideItem?.title || pageTitle,
        description,
      ),
    ];

    const generateStructuredData = () => {
      if (guideItemType === types.station) {
        return {
          '@type': 'RadioBroadcastService',
          '@id': 'RadioBroadcastService',
          callSign,
          broadcastDisplayName: callSign,
          broadcaster: callSign,
          broadcastFrequency: frequency
            ? {
                '@type': 'BroadcastFrequencySpecification',
                broadcastFrequencyValue: frequency || undefined,
                broadcastSignalModulation: band,
              }
            : undefined,
        };
      }

      if (guideItemType === types.program) {
        const eventStrucutredData = {
          '@type': 'BroadcastEvent',
          isLiveBroadcast: isLive,
          startDate: program?.eventStartTimeUtc,
        };

        if (isShow(program)) {
          return eventStrucutredData;
        }

        if (isGame(program)) {
          return {
            ...eventStrucutredData,
            broadcastOfEvent: {
              '@type': 'SportsEvent',
              name: guideItemTitle,
              image: teamsInfo?.firstTeamLogoUrl,
              organizer: {
                '@type': 'Organization',
                name: 'TuneIn',
                url: 'https://tunein.com',
              },
              homeTeam: {
                '@type': 'SportsTeam',
                name: teamsInfo?.firstTeamTitle,
                image: teamsInfo?.firstTeamLogoUrl,
              },
              awayTeam: {
                '@type': 'SportsTeam',
                name: teamsInfo?.secondTeamTitle,
                image: teamsInfo?.secondTeamLogoUrl,
              },
              startDate: program?.eventStartTimeUtc,
              eventStatus: 'https://schema.org/EventScheduled',
              eventAttendanceMode:
                'https://schema.org/OnlineEventAttendanceMode',
              location: {
                '@type': 'VirtualLocation',
                url: fullUrlWithAbsoluteRoot,
              },
            },
          };
        }

        if (isPodcastProfile(guideItem)) {
          if (rootGenre === 'audiobooks') {
            const getPropertyFromDescription = (property) => {
              const re = new RegExp(`${property} - [^\.\n\r]+`, 'g'); // eslint-disable-line no-useless-escape
              const str = guideInfoDescription.match(re)?.[0];

              if (str) {
                return str.replace(`${property} - `, '');
              }
            };

            const datePublished = getPropertyFromDescription('Published Date');
            const author = getPropertyFromDescription('Author');
            const narrator = getPropertyFromDescription('Narrator');

            return {
              '@type': 'Audiobook',
              '@id': fullUrlWithAbsoluteRoot,
              datePublished,
              author: author
                ? {
                    '@type': 'Person',
                    name: author,
                  }
                : undefined,
              readBy: narrator
                ? {
                    '@type': 'Person',
                    name: narrator,
                  }
                : undefined,
              provider: 'TuneIn',
              mainEntityOfPage: fullUrlWithAbsoluteRoot,
            };
          }

          return {
            '@type': 'PodcastSeries',
          };
        }
      }

      return {};
    };

    return (
      <div
        className={classNames(css.container, {
          [css.discord]: isDiscord,
          [css.mobile]: isMobile,
        })}
      >
        <SeoHelmet
          url={fullUrlWithAbsoluteRoot}
          title={pageTitle}
          name={guideItemTitle}
          image={logoUrl}
          description={description}
          keywords={keywords}
          meta={meta}
          links={links}
          additionalJsonLd={generateStructuredData()}
          includeJsonLd
        />
        <div
          className={commonCss.containerItemsWrapper}
          style={{ opacity: guideItem.isFetching ? 0.5 : 1 }}
        >
          <Header
            handleMoreClick={handleMoreClick}
            canExpand={canExpand}
            shouldShowMore={shouldShowMore}
            selectedGuideId={selectedGuideId}
            location={location}
            routeProps={routeProps}
            breakpoint={breakpoint}
            canShowAds={canShowAds}
          />
          {shouldShowInContentVideoAd && <FreeStarVideoAdContainer />}
          {shouldShowDisplayAd && (
            <section className="row">
              <div className="col-sm-12">
                <DisplayAd
                  style={{ padding: '15px 0' }}
                  guideId={routeProps.guideContext?.guideId}
                  matchUrl={routeProps.matchUrl}
                  isProfile
                  {...getDisplayAdAttributes(
                    gptSlotNames.profile_mid,
                    isFreestarEnabled,
                  )}
                />
              </div>
            </section>
          )}
          <section className="row">
            <div
              className={
                shouldShowProfileAdInContentArea ? 'col-xs-7' : 'col-xs-12'
              }
            >
              <ContainerItemsLoader
                guideItem={guideItem}
                items={guideItem.containerItems}
                itemsStyles={guideItem.metadata?.styles}
                isFetching={guideItem.isFetching}
                selectedContentId={selectedGuideId}
                breakpoint={breakpoint}
                routeProps={routeProps}
                isProfilePage
              />
            </div>
            {shouldShowProfileAdInContentArea && (
              <div className="col-xs-4 col-xs-offset-1">
                <DisplayAd
                  style={{
                    float: 'right',
                    paddingLeft: cssVariables['--extra-margin'],
                    paddingBottom: '25px',
                  }}
                  guideId={routeProps.guideContext?.guideId}
                  matchUrl={routeProps.matchUrl}
                  isProfile
                  {...getDisplayAdAttributes(
                    gptSlotNames.profile_side,
                    isFreestarEnabled,
                  )}
                />
                {/* important: the key prop allows the component to remount and log upsell activity on pathname change */}
                <UpsellRightSidebar
                  key={location.pathname}
                  guideId={guideItem.guideId}
                  isProfile
                />
              </div>
            )}
          </section>
        </div>
      </div>
    );
  }
}

function getProfile(state, props) {
  return state.profiles[props?.routeProps?.guideContext?.guideId];
}

export function mapStateToProps(state, ownProps) {
  const { guideId, topicGuideId, audioClipId } =
    ownProps?.routeProps?.guideContext || {};
  const { profiles, app } = state;
  const playerStatus = selectPlayerStatus(state);
  const isTunePrimed = selectIsTunePrimed(state);
  const { isMobile, allowAutoplay } = app;
  const guideItem = profiles[guideId];
  const topicGuideItem = profiles[topicGuideId];
  const hasTopicFetchFailed = profiles?.[topicGuideId]?.hasFailed;

  return {
    topicGuideItem,
    playerStatus,
    isTunePrimed,
    isAuthenticated: selectIsAuthenticated(state),
    selectedGuideId: guideId,
    topicGuideId: topicGuideId || null,
    audioClipId: audioClipId || null,
    guideItem,
    isMobile,
    allowAutoplay,
    isTopicIdInvalid: !!hasTopicFetchFailed,
    guideItemPathname: selectGuideItemPathname(state),
    tunedGuideId: selectTunedGuideId(state),
    isConsentReady: selectIsConsentReady(state),
    isFreestarEnabled: selectExperiment(state, FREESTAR_ENABLED),
    isBottomBannerEnabled: selectExperiment(state, BOTTOM_BANNER_ENABLED),
    isFreestarPrimisVideoEnabled: selectExperiment(
      state,
      FREESTAR_PRIMIS_VIDEO_ENABLED,
    ),
  };
}

const actions = {
  fetchProfile,
  tune,
  logClientError,
  logWebActivity,
};

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default flow(
  withCollapsibleState,
  connectWithFailureState(getProfile),
  connect(mapStateToProps, mapDispatchToProps),
)(Profile);
